<template>
  <v-container class="mt-12">
    <v-row class="pb-8">
      <v-col cols="12" lg="12" md="12" sm="12" class="pt-12 px-6">
        <p
          class="accent--text pt-2 caption"
          style="
            border-bottom: 1px solid black;
            padding-bottom: 5px;
            width: max-content;
            border-color: #a67d58;
          "
        >
          {{ $t("home.projects.project") }}
        </p>
        <h2 class="accent--text">
          {{ $t("home.projects.OUR_LATEST_PROJECTS") }}
        </h2>
      </v-col>

      <v-col cols="12" lg="12" md="12" sm="12" class="px-12">
        <img
          width="100%"
          height="360px"
          src="../../assets/photo_2023-04-01_23-05-55.jpg"
        />
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12" class="px-6">
        <v-card
          @click="1"
          elevation="0"
          :class="{ 'on-hover': hover }"
          class="rounded-t-0"
        >
          <!-- <v-img
        class=" align-center "
          style="object-fit: cover;"
          width="100%"
          height="400px"
          src="../../assets/AlSalam2.jpg"
          gradient="to bottom , rgb(5 3 0 / 60%), rgb(5 3 0 / 60%)"
        >
         <v-icon
                color="white"
                x-large
                class="d-flex justify-center "
                >mdi-play-outline</v-icon
              >
        </v-img> -->
          <!-- <youtube width="100%" :video-id="videoId" ref="youtube" @playing="playing"></youtube> -->
        </v-card>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" class="pt-8 px-6">
        <h4 class="accent--text">
          <b>{{ $t("project.AlSalamResidentialComplex") }}</b>
        </h4>
        <p class="pt-2">MAY 16, 2023</p>
        <p class="caption">
          {{ $t("project.AlSalamResidentialComplex_desc") }}
        </p>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" class="text-center">
        <v-btn
          class="mt-6"
          outlined
          color="primary"
          to="/projectInfo=AlSalamResidentialCompound&photo_2023-04-01_23-03-40.jpg&%7B%22id%22:4,%22imgsrc%22:%22photo_alsalam.jpg%22,%22imgsrc2%22:%22Picture2.png%22,%22title%22:%22AlSalamResidentialComplex%22,%22date%22:%222021%20%22,%22client%22:%22AlSalam%22,%22start%22:%222021%22,%22end%22:%22Underimplementation%22,%22description%22:%22AlSalamResidentialComplex_sup%22,%22allDes%22:%22AlSalamResidentialComplex_desc%22%7D"
        >
          <h5 class="accent--text">{{ $t("readmore") }}</h5>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Install",
  data: () => ({
    isActive: false,
    videoId: "TmOPUpBtvt0",
  }),
  computed: {
    plugins() {
      return JSON.parse(JSON.stringify(this.$t("home.install.plugins")));
    },
    player() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log(" we are watching!!!");
    },
  },
};
</script>

<style lang="scss" scoped></style>
